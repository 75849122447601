import React, { useState, useEffect } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { joiningFeeApi } from "redux/JoiningFeeApi/API";
import { paymentStatusApi } from "redux/PaymentStatus/API";
import MWLoader from "components/MWLoader";
import VerifiedIcon from "@mui/icons-material/Verified";
import RefreshIcon from "@mui/icons-material/Refresh";
import PaymentIcon from "@mui/icons-material/Payment";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MWExceptionList from "components/MWExceptionList";
import MWPageTitle from "components/MWPageTitle";
import PageLayout from "components/MWPageLayout";
export default function Payment() {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <MWLoader /> : null;
  const [, setConsultationType] = useState("");
  const [, setDoctorTeamType] = useState("");
  const [, setResponseTimeType] = useState("");
  const [, setToastSuccessMessage] = useState("");
  const [, setToastErrorMessage] = useState("");
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  function paymentLink(response: any) {
    window.open(response);
  }
  const [, setPaymentDetailsId] = useState("");
  function checkPaymentStatusDetails(id: any) {
    setPaymentDetailsId(id);
    checkPaymentStatusDetailsApicall(id);
  }
  const paymentStatusValue = useSelector(
    (state: AppState) => state.paymentStatus
  );

  function checkPaymentStatusDetailsApicall(id: any) {
    dispatch(paymentStatusApi({ paymentId: id }));
  }

  const body = {
    caseId: caseid,
  };
  const paymentListforOwnerValue = useSelector(
    (state: AppState) => state.joiningPaymentFee
  );

  let paymentListValue = [];
  if (paymentListforOwnerValue?.joiningFee.paymentList !== undefined) {
    for (
      var i = 0;
      i < paymentListforOwnerValue?.joiningFee.paymentList.length;
      i++
    ) {
      let paymentid = paymentListforOwnerValue?.joiningFee.paymentList[i].id;
      let selectedResponse =
        paymentListforOwnerValue?.joiningFee.paymentList[i].paymentLink;
      let description =
        paymentListforOwnerValue?.joiningFee.paymentList[i].note === ""
          ? "No Details Available"
          : paymentListforOwnerValue?.joiningFee.paymentList[i].note;
      let amount: any =
        paymentListforOwnerValue?.joiningFee.paymentList[i].amount === ""
          ? "Amount Not Available"
          : paymentListforOwnerValue?.joiningFee.paymentList[i].amount;
      let round_amount = amount / 100;
      let createDate =
        paymentListforOwnerValue?.joiningFee.paymentList[i].create_date == null
          ? ""
          : new Date(
              paymentListforOwnerValue?.joiningFee.paymentList[i].create_date
            ).toLocaleDateString("en-US", DATE_OPTIONS);
      let paymentstatus =
        paymentListforOwnerValue?.joiningFee.paymentList[i].status === null
          ? ""
          : paymentListforOwnerValue?.joiningFee.paymentList[i].status;

      paymentListValue.push([
        description,
        createDate,
        "Rs. " + round_amount,
        <Button
          variant="outlined"
          onClick={() => paymentLink(selectedResponse)}
          startIcon={<PaymentIcon />}
        >
          Pay Now
        </Button>,
        <Typography>{paymentstatus}</Typography>,
        <Button
          variant="outlined"
          onClick={() => checkPaymentStatusDetails(paymentid)}
          disabled={
            paymentstatus === "paid" || paymentstatus === "free" ? true : false
          }
          startIcon={<VerifiedIcon />}
        ></Button>,
      ]);
    }
  }
  const getDoctorPaymentList: any[] = paymentListValue;
  const getPaymentList = () => {
    dispatch(joiningFeeApi(body));
  };
  useEffect(() => {
    // Do api call here....
    dispatch(joiningFeeApi(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentListforOwnerValue !== undefined) {
      let consultationValue =
        paymentListforOwnerValue.joiningFee.consultationType === ""
          ? "Not Selected"
          : paymentListforOwnerValue.joiningFee.consultationType;
      let responseTimeValue =
        paymentListforOwnerValue.joiningFee.responseTimeType === ""
          ? "Not Selected"
          : paymentListforOwnerValue.joiningFee.responseTimeType;
      let doctorSelect =
        paymentListforOwnerValue.joiningFee.doctorTeamType === ""
          ? "Not Selected"
          : paymentListforOwnerValue.joiningFee.doctorTeamType;
      setConsultationType(consultationValue);
      setResponseTimeType(responseTimeValue);
      setDoctorTeamType(doctorSelect);
    }
    setIsLoading(paymentListforOwnerValue?.loading);
    paymentListforOwnerValue?.loading
      ? setIsLoading(true)
      : setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentListforOwnerValue]);

  useEffect(() => {
    if (paymentStatusValue?.paymentStatusRes?.message !== undefined) {
      if (paymentStatusValue?.paymentStatusRes?.message !== "") {
        setToastSuccessMessage(paymentStatusValue?.paymentStatusRes?.message);
        dispatch(joiningFeeApi(body));
      }
    }
    if (paymentStatusValue?.errRes !== "") {
      setToastErrorMessage(paymentStatusValue?.errRes);
      setIsLoading(false);
    }
    setIsLoading(paymentStatusValue?.loading);
    paymentStatusValue?.loading ? setIsLoading(true) : setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatusValue]);

  const pageBody = (
    <Card>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={getPaymentList}
              sx={{ textTransform: "none" }}
            >
              {t("payment.reloadpaylist", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
      <CardContent>
        {getDoctorPaymentList && getDoctorPaymentList.length ? (
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Create Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Pay</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>Check Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getDoctorPaymentList.map((row: any) => (
                  <TableRow
                    key={row.description}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">{row[0]}</TableCell>
                    <TableCell align="left">{row[1]}</TableCell>
                    <TableCell align="left">{row[2]}</TableCell>
                    <TableCell align="left">{row[3]}</TableCell>
                    <TableCell align="left">{row[4]}</TableCell>
                    <TableCell align="left">{row[5]}</TableCell>
                    <TableCell align="left">{row[6]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {loadingMarkup}
      <PageLayout>
        <MWPageTitle title={t("payment.paylist", { ns: ["home"] })!} />
        {pageBody}
      </PageLayout>
    </Box>
  );
}
